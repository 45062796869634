import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Layout from "../components/layout";

const StyledHome = styled.div`
  background: white url(/img/dirk.jpg) 100% 100% / 450px no-repeat;
  margin: -1rem;
  padding: 1rem 1rem 320px;
`;

export default ({ location }) => (
  <Layout location={location}>
    <Helmet>
      <title>Dirk's Jukebox</title>
    </Helmet>
    <StyledHome>
      <h1>Welkom bij Dirk's Jukebox!</h1>
      <p>
        Het zal zo rond 2000 geweest zijn dat ik een jukebox kocht,
        waarna de jacht op singletjes werd geopend. Al snel kwam het
        moment dat ik zo veel singles had, dat het moeilijk werd om
        bij te houden welke ik al wel en niet had, dus het was
        noodzaak om een kleine database op te zetten met mijn
        verzameling. Maar stel dat je computer crasht, dan heb je een
        probleem. Nou woont en werkt mijn zoon{" "}
        <a
          href="https://markkater.nl"
          target="_blank"
          rel="noreferrer"
        >
          Mark
        </a>{" "}
        in San Francisco, als web-programmeur, en die opperde om er
        een online versie van te maken, met veel meer mogelijkheden
        dan dat ik in mijn MS Access database had.
      </p>
      <p>
        Wat begon als een pagina met mijn verzameling singles, is nu
        een fantastische interactieve site, die overigens nog steeds
        in ontwikkeling is. Heb je suggesties, schroom niet en laat
        het weten. En niet onbelangrijk:{" "}
        <b>
          heb je nog oude singles thuis liggen die je nooit meer
          hoort, voeg ze toe aan "Dirk's Jukebox" en je kunt ze weer
          horen wanneer en waar je wilt!
        </b>{" "}
        :)
      </p>
      <p>Dirk Kater</p>
    </StyledHome>
  </Layout>
);
